<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reclamaciones')+': '+$t('Salud')}} </h3>
		</div>
		<div class="p-col-12" v-if="!mostrar">
			<div class="card">
                <DataTable :value="claims" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
                    <template #header>
                        <div class="table-header">
                            <div>
                                {{$t('Fecha')+'s'}}:
								<InputMask v-model="fecha1" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999" @change="Limpiar()"/>
								<InputMask v-model="fecha2" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999" @change="Limpiar()"/>
                            </div>
                            <Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Buscar()"></Dropdown>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
							<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'es'">
								{{slotProps.data.estado_nombre}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'en'">
								{{slotProps.data.estado_name}}
							</strong>
                        </template>
                    </Column>   
                    <Column field="codigo" :header="$t('Claim')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                              
                        </template>
                    </Column>
                    <Column field="poliz" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.poliz}}                              
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente}}                              
                        </template>
                    </Column>
                    <Column field="fecha" :header="$t('Fecha del incidente')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.fecha)}}
                        </template>
                    </Column>
                    <Column field="forenc" :header="$t('Forence')" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{slotProps.data.forenc}}<br>
							<Tag class="p-mr-2" icon="pi pi-exclamation-triangle" severity="warning" :value="$t('Pendiente')" v-if="slotProps.data.forence"></Tag>
                        </template>
                    </Column>
                    <Column field="createdby" :header="$t('Creado por')" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{slotProps.data.createdby}}
                        </template>
                    </Column>
					<Column :header="$t('Acciones')" headerStyle="width: 80px">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="Editar(slotProps.data)" v-if="edit && slotProps.data.estado<=3"/>
							<Button icon="pi pi-eye" class="p-button-rounded p-button-info p-mr-2" @click="Editar(slotProps.data)" v-if="edit && slotProps.data.estado>3"/> 
						</template>
					</Column>

                </DataTable>
			</div>
        </div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Póliza')}}</h4>
					</template>
					<template #content>
						<div class="p-grid">
							<div class="p-col-4">
								{{$t('Número')}}: <strong>{{seleccion.poliz.codigo}}</strong>			
							</div>
							<div class="p-col-2">
								{{$t('Cliente')}}: <strong>{{seleccion.poliz.cliente}}</strong>			
							</div>
							<div class="p-col-3" v-if="i18n.locale() == 'es'">
								<strong>{{seleccion.salud.tipopoliza_nombre}}</strong> 			
							</div>
							<div class="p-col-3" v-else>
								<strong>{{seleccion.salud.tipopoliza_name}}</strong> 			
							</div>
							<div class="p-col-3" v-if="i18n.locale() == 'es'">
								<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" style="background-color:yellow; color: #ffffff" v-if="seleccion.poliz.estado==4"/>
								<Avatar icon="bi bi-shield-check" class="p-mr-2" style="background-color:green; color: #ffffff" v-if="seleccion.poliz.estado==5"/>
								<Avatar icon="bi bi-shield-slash" class="p-mr-2" style="background-color:red; color: #ffffff" v-if="seleccion.poliz.estado==6"/>
								<strong>{{seleccion.poliz.estado_nombre}}</strong> 			
							</div>
							<div class="p-col-3" v-else>
								<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" style="background-color:yellow; color: #ffffff" v-if="seleccion.poliz.estado==4"/>
								<Avatar icon="bi bi-shield-check" class="p-mr-2" style="background-color:green; color: #ffffff" v-if="seleccion.poliz.estado==5"/>
								<Avatar icon="bi bi-shield-slash" class="p-mr-2" style="background-color:red; color: #ffffff" v-if="seleccion.poliz.estado==6"/>
								<strong>{{seleccion.poliz.estado_name}}</strong> 			
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>

		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Información Básica')}}</h4>
					</template>
					<template #content>
						<div class="p-grid">
							<div class="p-col-3">
								{{$t('Número ID')}}: <strong>{{seleccion.code}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Localidad')}}: <strong>{{seleccion.salud.localidadn}}</strong>			
							</div>
							<div class="p-col-6">
								{{$t('Nombre Completo')}}: <strong>{{seleccion.nombre}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Teléfono móvil')}}: <strong>{{seleccion.celular}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Teléfono privado')}}: <strong>{{seleccion.tlflocal}}</strong>			
							</div>
							<div class="p-col-6">
								{{$t('Correo')}}: <strong>{{seleccion.correo}}</strong>			
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>


		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Accordion>
				<AccordionTab :header="$t('Beneficiario')+' '+(index+1)" v-for="(item, index) in seleccion.beneficiarios" :key="index">
					<h4>{{$t('Información Básica')}}</h4>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-field p-col-12 p-md-2">
									<label>{{$t('Número ID')}}</label>
									<InputText v-model="item.code" type="text"/>
								</div>
								<div class="p-field p-col-12 p-md-10">
									<label>{{$t('Nombre Completo')}}</label>
									<InputText v-model="item.nombre" type="text"/>
								</div>
								<div class="p-field p-col-12 p-md-2">
									<label>{{$t('Teléfono móvil')}}</label>
									<InputMask v-model="item.celular" mask="+99999 999999"/>
									<small class="p-error" v-if="i18n.locale() == 'es'">Incluir código del país</small>
									<small class="p-error" v-if="i18n.locale() == 'en'">Include country code</small>                    
								</div>
								<div class="p-field p-col-12 p-md-2">
									<label>{{$t('Teléfono privado')}}</label>
									<InputMask v-model="item.tlflocal" mask="+99999 999999"/>
									<small class="p-error" v-if="i18n.locale() == 'es'">Incluir código del país</small>
									<small class="p-error" v-if="i18n.locale() == 'en'">Include country code</small>                    
								</div>                      
								<div class="p-field p-col-12 p-md-8">
									<label>{{$t('Correo')}}</label>
									<InputText v-model="item.correo" type="email"/>
								</div>
							</div>
						</div>

						<div class="p-col-12" v-if="seleccion.forence_user.code>0 && item.total>0">
							<h4>{{$t('Forence')}}</h4>
							<div class="p-grid">
								<div class="p-col-2">
									{{$t('Observaciones')}}:	
								</div>
								<div class="p-col-10">
									<strong>{{item.observacion}}</strong> 			
								</div>
								<div class="p-col-3">
									{{$t('Fecha')}}: <strong>{{seleccion.forence_fecha}}</strong> 			
								</div>
								<br><br>
								<div class="p-col-8">
									<div class="p-grid">
										<div class="p-col-12">
											<DataTable :value="item.forence" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
												:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
												<Column :header="$t('Descripción')">
													<template #body="slotProps">
														{{slotProps.data.descripcion}}<br>
														<Tag class="p-mr-2" icon="pi pi-exclamation-triangle" severity="warning" :value="$t('De segunda')" v-if="slotProps.data.secunda"></Tag>
													</template>
												</Column>
												<Column :header="$t('Costo')">
													<template #body="slotProps">
														{{slotProps.data.costo}}
													</template>
												</Column>
												<Column :header="$t('Cantidad')">
													<template #body="slotProps">
														{{slotProps.data.cantidad}}
													</template>
												</Column>
												<Column :header="$t('Total')">
													<template #body="slotProps">
														{{slotProps.data.costo*slotProps.data.cantidad}}
													</template>
												</Column>
											</DataTable>
										</div>
										<br><br>
										<div class="p-col-8">
											<strong>Subtotal:</strong>	
										</div>
										<div class="p-col-4">
											<strong>{{parseFloat(item.subtotal).toFixed(2)}}</strong>	
										</div>
										<br><br>
										<div class="p-col-4">
											<strong>{{$t('Impuesto')}}:</strong>	
										</div>
										<div class="p-col-4">
											<strong>{{parseFloat(item.impuesto).toFixed(2)}}%</strong>	
										</div>
										<div class="p-col-4">
											<strong>{{(parseFloat(item.subtotal)*parseFloat(item.impuesto/100)).toFixed(2)}}</strong> 			
										</div>
										<br><br>
									</div>
								</div>
								<div class="p-col-4">
									<DataTable :value="item.anexos" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
										:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
										<Column :header="$t('Documentos')">
											<template #body="slotProps">
												{{slotProps.data.descripcion}}<br>
												<Tag class="p-mr-2" icon="pi pi-exclamation-triangle" severity="warning" :value="$t('De segunda')" v-if="slotProps.data.secunda"></Tag>
											</template>
										</Column>
										<Column :header="$t('Ver')">
											<template #body="slotProps">
												<img style="width: 25px;" :src="url.upload+slotProps.data.codigo" v-if="slotProps.data.format=='jpg'"  @click="IMG(url.upload+slotProps.data.codigo)"/>
												<img style="width: 25px;" src="images/pdf.jpg" v-if="slotProps.data.format=='pdf'" @click="VerPDF(slotProps.data.codigo)"/>
											</template>
										</Column>
									</DataTable>
									<OverlayPanel ref="op1" appendTo="body" :showCloseIcon="true">
										<img style="width: 350px;" :src="imagen"/>
									</OverlayPanel>														
								</div>
								<br><br>
								<div class="p-col-12"><br><br>
									<div class="p-grid">
										<div class="p-col-5">
											<h3><strong>{{$t('Total')}}:</strong></h3>
										</div>
										<div class="p-col-2">
											<h3><strong>{{parseFloat(item.total).toFixed(2)}}</strong></h3>	
										</div>
										<div class="p-col-3">
											<Button :label="$t('Documento de Aceptación')" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(item)"/>
										</div>
									</div>								
								</div>
							</div>
						</div>
					</div>
				</AccordionTab>
			</Accordion>
		</div>

		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Estatus')}}:  
							<strong :style="'color:'+seleccion.color" v-if="i18n.locale() == 'es'">
								{{seleccion.estado_nombre}}
							</strong>
							<strong :style="'color:'+seleccion.color" v-if="i18n.locale() == 'en'">
								{{seleccion.estado_name}}
							</strong>
						</h4>
					</template>
					<template #content>					
						<div class="p-fluid p-formgrid p-grid">						
							<div class="p-field p-col-6">
								<div class="p-fluid p-formgrid p-grid">
									<div class="p-field p-col-6">
										<label>{{$t('Fecha en que ocurrió el incidente')}}</label>
										<InputText v-model="seleccion.fecha" type="date" disabled/>
										<small v-show="validationErrors.fecha" class="p-error">{{$t('required')}}</small>
									</div>
								</div>
							</div>
							<div class="p-field p-col-6">
								<div class="p-fluid p-formgrid p-grid">
									<div class="p-field p-col-6">
										<label>{{$t('Monto de Reserva')}}: </label>
										<InputText v-model="seleccion.estimacion" type="number" disabled/>
									</div>
									<div class="p-field p-col-12	">
										<label>{{$t('Forence')}}</label>
										<Dropdown id="state" v-model="seleccion.forence_user" :options="forences" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" :disabled="seleccion.estado>=3"></Dropdown>
										<small v-show="validationErrors.forence" class="p-error">{{$t('required')}}</small>
									</div>
									<div class="p-field p-col-4" v-if="seleccion.forence_user.code>0">
										<Checkbox v-model="seleccion.forence" :binary="true" :disabled="seleccion.estado>=3"/>
										{{$t('Notificar al forence')}}
									</div>
									<div class="p-field p-col-12" v-if="seleccion.forence_user.code>0">
										<label>{{$t('Observaciones')}}: </label>
										<Textarea v-model="seleccion.observacion" rows="3"/>
									</div>
									<br><br>
									<div class="p-field p-col-12" v-if="seleccion.estado==3 || seleccion.estado==4">
										<strong>{{$t('Documento de Aceptación')}}</strong>
									</div>
									<div v-for="(item, index) in seleccion.beneficiarios" :key="index" >
										<div class="p-field p-col-12" v-if="seleccion.estado==3 || seleccion.estado==4">
											{{$t('Beneficiario')+' '+(index+1)}}
											<FileUpload :chooseLabel="$t('Subir PDF')" uploadLabel="Cargar" mode="basic" :name="pdf" :url="url.api+'upload.php'"  accept="application/pdf" :maxFileSize="4000000" @upload="myUploader2(pdf,index)" :auto="true" :disabled="seleccion.estado>3"/>
											<small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
										</div>
										<div class="p-field p-col-6" v-if="seleccion.estado==3 || seleccion.estado==4">
											<img style="width: 75px;" src="images/pdf.jpg" v-if="item.aceptacion" @click="VerPDF(item.aceptacion)"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
					<template #footer>
						<Button :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary p-mr-2 p-mb-2" @click="Guardar()" v-if="seleccion.estado<=3"/>
						<Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger p-mr-2 p-mb-2" @click="Mostrar"/>
						<Dropdown class="p-mr-2 p-mb-2" id="state" v-model="estado" :options="estados" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" style="width:250px;"  v-if="seleccion.estado<=3" @change="ActEstado()"></Dropdown>
					</template>
				</Card>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/ApiConsulta";
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import url from "@/service/_URL";
	import PDF from "../service/PDF";

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: null,
				opciones: [{nombre: 'Todos', name: 'All', code: -1, color: ''}],
				resultado: [],
				insert: false,
				edit: false,
				del: false,
				poliza: null,
				filters: {},
				selectedCustomers: null,
				submitted: false,
				size: '60vw',
				polizas: [],
				seleccion: {},
				mostrar: false,
				forences: [],
				estados: [],
				anexos2: [],
				beneficiarios: [],
				descripcion: null,
				validationErrors: {},
				estado: null,
				estado2: null,
				claims: [],
				pdf: null,
				img: null,
				pdf2: null,
				lpdf: null,
				imegan: null,
				fecha1: null,
				fecha2: null,
			}
		},
		created() {
			this.url = url;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			const tokgen = new Token(256);
			this.pdf = tokgen.generate();
			this.lpdf = tokgen.generate();
			this.img = tokgen.generate();
			this.pdf2 = tokgen.generate();
			const Consulta = new API('Reclamos');
			this.fecha1 = Consulta.fstring(this.$store.state.fecha);
			this.fecha2 = Consulta.fstring(this.$store.state.fecha);
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.$store.commit('Loading');
				this.asunto = [];
				this.polizas = [];
				this.mostrar = false;
				const Consulta = new API('Reclamos');
				Consulta.Ini('Claim3').then(result => {
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.fecha1 = Consulta.fstring(result.consult.fecha);
					if(result.consult.polizas){
						this.polizas = result.consult.polizas;
					}
					if(result.consult.forences){
						this.forences = result.consult.forences;
					}
					if (result.consult.estados){
						this.estados = result.consult.estados;
					} 
					if (result.consult.claims){
						this.claims = result.consult.claims;
						this.resultado = result.consult.claims;
					}
					if (result.consult.opciones){
						result.consult.opciones.forEach(element => {
							this.opciones.push(element);
						});
					}
					this.$store.commit('Loading');
				}); 
			},
			Limpiar(){
				this.claims = [];	
				this.opcion = null;	
			},
			Buscar(){
				this.$store.commit('Loading');
				this.claims = [];
				const Consulta = new API('Reclamos');
				Consulta.Procesar('Claim3',{
					caso: 	'BuscarEval',
					fecha1: Consulta.fdate(this.fecha1),
					fecha2: Consulta.fdate(this.fecha2),
					opcion: this.opcion.code,
					}).then(response => {
						//this.$store.state.error = response.result.claims[0];	
						if (response.result.claims){
							this.claims = response.result.claims;
						}
						this.$store.commit('Loading');
					});
			},
			Ver(data){
				const doc = new PDF();
				doc.Aceptacion(this.seleccion,data,3);
			},
			ActEstado(){
				this.seleccion.estado = this.estado.code;
			},
			Editar(data) {
				this.$store.commit('Loading');
				this.seleccion = {};
				const Consulta = new API('Reclamos');
				Consulta.Procesar('Claim3',{
					caso: 	'MostEval',
					id: data.id,
					}).then(response => {
						if (response.result.claims){
							this.seleccion = response.result.claims;
							this.estados.forEach(element => {
								if(this.seleccion.estado==element.code){
									this.estado = element;
								}
							});
							this.forences.forEach(element => {
								if(this.seleccion.forence_user==element.code){
									this.seleccion.forence_user = element;
								}
							});
							this.mostrar = true;
						}
						this.$store.commit('Loading');
					});
			},
			IMG(imegan){
				this.imagen = imegan;
				this.toggle1(event);
			},
			toggle1(event) {
				this.$refs.op1.toggle(event);
			},
			VerPDF(document){
				window.open(url.upload+document+'.pdf', '_blank');
			},
			DeleteDoc(selec) {
				this.anexos2 = this.anexos2.filter(val => val !== selec);
			},
			myUploader1(pdf) {
				this.$store.commit('Loading');
				const Consulta = new API('Reclamos');
				Consulta.Procesar('Claim3',{
					caso: 	'RevPDF',
					foto: pdf,
				}).then(response => {
					if(response.result){					
						this.seleccion.lesionespdf = pdf;
						const tokgen = new Token(256);
						this.pdf = tokgen.generate();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error en Foto'), 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			myUploader2(pdf,index) {
				this.$store.commit('Loading');
				const Consulta = new API('Reclamos');
				Consulta.Procesar('Claim3',{
					caso: 	'RevPDF',
					foto: pdf,
				}).then(response => {
					if(response.result){	
						this.seleccion.beneficiarios[index].aceptacion = pdf;
						const tokgen = new Token(256);
						this.pdf = tokgen.generate();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error en Foto'), 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			myUploader3(img) {
				if (this.descripcion == null || this.descripcion == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Descripción'), life: 10000});
				} else {
					this.$store.commit('Loading');
					const Consulta = new API('Reclamos');
					Consulta.Procesar('Claim3',{
						caso: 	'RevFoto',
						foto: img,
						descripcion: this.descripcion,
						claim: this.seleccion.id,
					}).then(response => {
						if(response.result){					
							this.anexos2.push({
								id: 0, 
								claim: this.seleccion.id,
								descripcion:  this.descripcion, 
								format: 'jpg', 
								codigo: response.result
							});
							this.descripcion = null;
							const tokgen = new Token(256);
							this.img = tokgen.generate();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('Error en Foto'), 
								life: 3000
							});
						}
						this.$store.commit('Loading');
					});
				}
			},
			myUploader4(pdf) {
				if (this.descripcion == null || this.descripcion == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Descripción'), life: 10000});
				} else {
					this.$store.commit('Loading');
					const Consulta = new API('Reclamos');
					Consulta.Procesar('Claim3',{
						caso: 	'RevPDF',
						foto: pdf,
						descripcion: this.descripcion,
						claim: this.seleccion.id,
					}).then(response => {
						if(response.result){					
							this.anexos2.push({
								id: 0, 
								claim: this.seleccion.id,
								descripcion:  this.descripcion, 
								format: 'pdf', 
								codigo: pdf
							});
							this.descripcion = null;
							const tokgen = new Token(256);
							this.pdf2 = tokgen.generate();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('Error en Foto'), 
								life: 3000
							});
						}
						this.$store.commit('Loading');
					});
				}
			},
			Guardar() {
				if (this.validateForm()) {	
					this.$store.commit('Loading');
					const Consulta = new API('Reclamos');          
					Consulta.Procesar('Claim3',{
						caso: 	'UPD',
						claim: this.seleccion,
						estado: this.estado,
					}).then(response => {
						if (response.result) {
							this.$toast.add({
								severity:'success',
								summary: this.$t('Éxito'), 
								detail: this.$t('Información Procesada'), 
								life: 3000
							});
							this.$store.commit('Loading');
							this.Mostrar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('Error'), 
								life: 3000
							});
							this.$store.commit('Loading');
						}
					});
				}
			},
			validateForm() {
				if (this.seleccion.fecha==null)
				this.validationErrors['fecha'] = true;
				else
				delete this.validationErrors['fecha'];
				if (this.seleccion.reserva==null)
				this.validationErrors['reserva'] = true;
				else
				delete this.validationErrors['reserva'];
				if (this.seleccion.forence_user==null)
				this.validationErrors['forence'] = true;
				else
				delete this.validationErrors['forence'];

				return !Object.keys(this.validationErrors).length;
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
